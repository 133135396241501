import * as React from "react"
import styled from "styled-components";
import Simple from "@templates/Simple"
import banner from "@images/banner.jpg";
import faspa from "@images/faspa.png";
import quipu from "@images/quipu.jpg";
import usnavy from "@images/usnavy.png";
import fogarty from "@images/fogarty.png";


const Page = () => {
  return (
    <Simple title={"CRI: Inicio"}>
      <Wrapper>
        <img src={banner} alt={banner} />
        <h1>
          Presentación del curso
        </h1>
        <p>
          La tarea de la comunidad científica es la de crear nuevo conocimiento a través de la investigación. Los investigadores buscan entender y mejorar el mundo en que vivimos. Y la sociedad desea y necesita creer en los resultados de las investigaciones. Por eso, la actividad científica debe desarrollarse en base a la honestidad, el análisis riguroso de los datos, el compromiso con la verdad y el seguimiento de normas profesionales.
        </p>
        <p>
          Más allá de cuestiones como la ética y la protección de los derechos de las personas que participan en investigaciones, que ya de por sí son relevantes, es de vital importancia formar investigadores con principios sólidos para una conducta responsable en investigación y de un gran compromiso con la integridad científica.
        </p>
        <p>
          Presentamos así este curso de Conducta Responsable en Investigación, que ofrece conceptos, casos y otros contenidos relacionados con los valores de la integridad científica y de las prácticas que sostienen una conducta responsable en investigación. De este modo, mediante el entrenamiento en estos temas, buscamos contribuir a un mejor desarrollo de la investigación científica en el Perú y, en general, en el mundo hispanoparlante.
        </p>
        <h1>
            Instructores
          </h1>
        <CardContainer>
          
          <Card>
            <h1>
              Patricia J. García

            </h1>
            <h2>
              Patricia J. García

            </h2>
           
            <p>Médico Internista, Infectóloga y Salubrista. Magíster en Salud Pública por la Universidad de Washington y Doctora en Medicina por la Universidad Peruana Cayetano Heredia (UPCH). Profesora Principal de la Facultad de Salud Pública y Administración de la UPCH e Investigadora de la Unidad de Epidemiología, ITS y VIH.

            </p>
          </Card>
          <Card>
            <h1>
              Andrés G. (Willy) Lescano

            </h1>
            <h2>
              MHS, MHS, PhD


            </h2>
           
            <p>Bachiller en Ingeniería de Sistemas, Magíster en Informática por la UPCH. Magíster en Bioestadística y Políticas en Salud y Doctor en Salud Internacional por la Johns Hopkins School of Hygiene and Public Health. Profesor asociado en la Facultad de Salud Pública y Administración de la UPCH.

            </p>
          </Card>
          <Card>
            <h1>
              Roxana Lescano
            </h1>
            <h2>
              MSc(c)


            </h2>
           
            <p>Bachiller en Derecho y Ciencias Políticas, y graduada del Diplomado de Bioética, Salud y Medioambiente. Egresada de la Maestría en Investigación Epidemiológica de la UPCH. Trabaja en el Centro de Investigación de Enfermedades Tropicales de la Marina de los EEUU (NAMRU-6), desde el año 2002 es Directora de Administración de la Investigación. 

            </p>
          </Card>


        </CardContainer>
        <ImageContainer>
          <img src={faspa} alt={faspa} />
          <img src={quipu} alt={quipu} />
          <img src={usnavy} alt={usnavy} />
          <img src={fogarty} alt={fogarty} />
        </ImageContainer>
        <p>
          El curso ha sido preparado por QUIPU - Centro Andino de Investigación y Entrenamiento en Informática para la Salud Global de la Universidad Peruana Cayetano Heredia y el Centro de Investigación de Enfermedades Tropicales de la Marina de los Estados Unidos (NAMRU-6). Este programa ha sido desarrollado con auspicio del Centro Internacional Fogarty de los Institutos Nacionales de Salud de los Estados Unidos, y está dirigido a quienes desarrollan actividades de investigación.
        </p>
      </Wrapper>
    </Simple>
  )
}

export default Page;

const Wrapper = styled.div`
width:100%;
padding: 1px;
display: flex;
flex-direction: column;
align-items: center;
color: var(---color4);

img{
  width:100%;
  margin: 2px 0;
}

h1{}

p{
  padding:10px;
  text-align: justify;
  color: var(---color4);
}
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color2);

  @media (min-width: 768px){
    flex-direction: row;
    align-items: baseline;
    padding: 10px 0;
    justify-content: space-around;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2px;

  @media (min-width:768px){
    width:32%;
  }

  h1{
    font-size:22px;
    color: var(--white);
    margin: 5px auto;
  }

  h2{
    font-size:16px;
    color: var(--color3);
    margin: 5px auto;
  }

  p{
    font-size:16px;
    color: var(--color3);
    margin: 5px auto;
  }

  img{
    border-radius: 50%;
  }

`;

const ImageContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
img{
  width: 100%;
}

@media (min-width: 768px){
  display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
img{
  width: 24%;
}
}
`; 